.loader-container2 {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.834)
        url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center
        no-repeat;
    z-index: 1;
}

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(255, 255, 255, 0.50);
    z-index: 1;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}



.fade-in-image {
animation: fadeIn 5s;
-webkit-animation: fadeIn 5s;
-moz-animation: fadeIn 5s;
-o-animation: fadeIn 5s;
-ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
0% { opacity: 0; }
100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
0% { opacity: 0; }
100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
0% { opacity: 0; }
100% { opacity: 1; }
}

@-o-keyframes fadeIn {
0% { opacity: 0; }
100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
0% { opacity: 0; }
100% { opacity: 1; }
}